.oops-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.0075em;
  text-align: center;
  color: "#fff";
}

.oops-text2 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-align: center;
}
