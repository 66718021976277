.title-stepper {
  width: 311px;
  left: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #fff;
}

.location {
  width: "79px";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.005em;
  font-feature-settings: "tnum" on, "lnum" on;
  margin-top: 0;
  margin-bottom: 0;
}

.location-number {
  width: "90px";
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.005em;
  font-feature-settings: "tnum" on, "lnum" on;
  margin-top: -3px;
  margin-bottom: 0;
  color: "black";
}

.label-price {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.003em;
  color: rgb(255 255 255 / 61.8%);
}
/* stylelint-disable-next-line */
.MuiSwitch-colorSecondary.Mui-checked {
  color: #f3bf59 !important;
}
/* stylelint-disable-next-line */
.MuiSwitch-colorSecondary {
  color: #f3bf59 !important;
}
/* stylelint-disable-next-line */
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #f3bf59 !important;
}
/* stylelint-disable-next-line */
.MuiSwitch-colorSecondary + .MuiSwitch-track {
  background-color: rgb(243 191 89 / 38.2%) !important;
}
/* stylelint-disable-next-line */
.inputRounded .MuiOutlinedInput-root {
  border-radius: 20px;
}
/* stylelint-disable-next-line */
.MuiMobileStepper-root {
  background-color: black;
}
/* stylelint-disable-next-line */
.MuiMobileStepper-dotActive {
  background-color: #f3bf59 !important;
}
/* stylelint-disable-next-line */
.MuiMobileStepper-dot {
  background-color: rgb(243 191 89 / 38.2%) !important;
  width: 10px;
  height: 10px;
}

.paypall-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 290px;
  height: 48px;
  background: #fff;
  box-shadow: 0 2px 6px rgb(67 118 254 / 30%);
  border-radius: 999px;
  font-weight: 600;
  font-size: 16px;
  color: blue;
  margin-top: 16px;
}

@media (min-width: 415px) {
  .order-card {
    text-align: left;
    padding: 24px;
    margin-top: 19px;
    margin-bottom: 19px;
    border-radius: 8px;
    background: #222;
    width: 798px !important;
    max-height: 115px !important;
  }
}

@media (max-width: 415px) {
  .order-card {
    text-align: center;
    padding: 24px;
    margin-top: 19px;
    margin-bottom: 19px;
    border-radius: 8px;
    background: #222;
    max-height: 208px !important;
    max-width: 327px !important;
  }
}
