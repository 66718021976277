.essential-card {
  text-align: left;
  padding: "24px";
  margin-top: 19px;
  margin-bottom: 19px;
  border-radius: 8px;
  max-width: 327px !important;
  min-height: 639px !important;
  background: #222;
}

.custom-card {
  text-align: left;
  height: 591px;
  max-width: 327px;
  left: 24px;
  top: 868px;
  border-radius: 8px;
  background: #222;
}

.title {
  text-align: left;
  width: 311px;
  height: 28px;
  padding-left: 14px;
  padding-top: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.0075em;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #fff;
}

.card-title {
  text-align: left;
  margin-top: 0;
  height: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.005em;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #f3bf59;
}

.price {
  text-align: left;
  width: 125px;
  height: 44px;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 130%;
  color: #fff;
  margin: 0;
}

.text {
  text-align: left;
  height: 32px;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.03em;
  color: #fff;
}

.title-of-the-list {
  width: 287px;
  height: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.005em;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #fff;
}

.list-item {
  margin-bottom: 14px;
  margin-top: 14px;
  left: 0%;
  color: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: 0.0168em;
  font-feature-settings: "tnum" on, "lnum" on;
}

.ligne {
  border: "1px solid rgba(255, 255, 255, 0.2)";
}

.check-style {
  color: "#F3BF59";
  width: "20px";
  padding-right: "14px";
}
