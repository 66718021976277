@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  background-color: black;
}

code {
  font-family: Poppins, sans-serif;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #9e9e9e;
  border-radius: 100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(44, 44, 44);
  border-radius: 100;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(61, 61, 61);
  border-radius: 100;
}