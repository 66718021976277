.container-counter {
  width: 112px;
  height: 35px;
  background: #000;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.btn-plus {
  max-width: 26px;
  width: 26px;
  height: 24px;
  background: #f3bf59;
  border-radius: 30px;
}

.btn-minus {
  max-width: 26px;
  width: 26px;
  height: 24px;
  background: #f3bf59;
  border-radius: 30px;
}

.counter {
  max-width: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #fff;
}
