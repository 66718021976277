/* stylelint-disable-next-line */
.inputRounded .MuiOutlinedInput-root .MuiInputBase-input {
  border-radius: 99px;
  color: white;
  border-color: white;
}
/* stylelint-disable-next-line */
.inputBase:hover {
  border: "1px solid rgb(243, 191, 89)";
}
