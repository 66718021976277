.default-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  box-shadow: 0 2px 6px rgb(67 118 254 / 30%);
  border-radius: 999px;
  font-weight: 600;
  font-size: 16px;
  color: rgb(0 0 0 / 61.8%);
  margin-top: 16px;

  /* margin-bottom: 24px; */
}
