.container {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 38.37px;

  @media (max-width: 1199px) {
    margin: 90px 20px 0;
  }

  @media (max-width: 950px) {
    width: auto;
    margin: 90px 20px 0;
    margin-top: 0;
  }

  @media (max-width: 411px) {
    width: auto;
    margin: 90px 20px 0;
    margin-top: 0;
  }
}

button {
  text-transform: uppercase;
}

.music {
  position: absolute;
  width: 72.82px;
  height: 21.63px;
  left: 75.18px;
  font-size: 1.6rem;
  color: #f3bf59;
}

.ilioa {
  position: absolute;
  width: 47.22px;
  height: 21.63px;
  left: 24px;
  font-size: 1.6rem;
  color: #fff;
}

p {
  text-align: left;
  align-self: left;
  font-family: 'Poppins', sans-serif;
}

* {
  font-family: 'Poppins', sans-serif;
}