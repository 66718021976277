.container-activating {
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
  height: 80vh;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 133%;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: "tnum" on, "lnum" on;
}

.model-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-oh {
  font-size: 48px;
  font-weight: 600;
}

.text-opps {
  font-size: 16px;
  width: 200px;
  letter-spacing: 0.005em;
  line-height: 150%;
  color: #fff;
}
